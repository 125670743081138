"use client";
// import { inAndroid, inApp, inIPhone, getSearchParams, inWechat } from "@/lib";
// import { getCommonData } from "@/lib/coat";
import { useEffect, useRef, useState } from "react";
import copy_ from "copy-to-clipboard";
import useCommonData from "@/hooks/useCommonData";

// const searchParams = getSearchParams();
// const { innerfromid, fromid, saleId, promotionId } = getCommonData();

function OpenApp({
  children,
  schemeURL = "bitautousedcar://?platform=web&action=openHome",
  innerfromId,
  fromId,
  className,
  onClick,
  hckukuid,
  ...rest
}) {
  const [scheme, setScheme] = useState(schemeURL);
  const wxRef = useRef();
  const {
    innerfromid,
    fromid,
    saleId,
    promotionId,
    inAndroid,
    hideOpenApp,
    inIPhone,
    inWechat,
  } = useCommonData();

  useEffect(() => {
    const commonQuery = new URLSearchParams(window?.tcc?.globalQuery);
    if (innerfromId) commonQuery.set("innerfromid", innerfromId);
    if (innerfromid) commonQuery.set("innerfromid", innerfromid);
    if (fromId) commonQuery.set("fromid", fromId);
    if (fromid) commonQuery.set("fromid", fromid);
    if (saleId) commonQuery.set("saleId", saleId);
    if (promotionId) commonQuery.set("promotionId", promotionId);
    if (hckukuid) commonQuery.set("hckukuid", hckukuid);
    const commonQueryStr = commonQuery.toString();
    setScheme(`${schemeURL}${commonQueryStr ? `&${commonQueryStr}` : ""}`);
  }, [innerfromid, fromid, saleId, promotionId]);

  let appstore = "https://apps.apple.com/app/id421347528?mt=8";
  if (inAndroid) {
    appstore = `https://img6.taocheche.com/ershouche/${hckukuid}.apk`;
    if (inWechat) {
      appstore = `https://sj.qq.com/appdetail/com.ucar.app`;
    }
  }

  const copy = () => {
    let str = "";
    if (inAndroid) {
      str = `hckuku{$#${scheme}`;
    }
    if (inIPhone) {
      str = `hckuku{$#${hckukuid || ""}@&${scheme}`;
    }
    console.info("复制到粘贴板的内容", str);

    copy_(str);
  };

  const handleOpenApp = ({ onClick, scheme }) => {
    if (onClick) onClick();
    copy();

    if (inIPhone) {
      window.location.href = `https://t.taocheche.com/universal-link?scheme=${encodeURIComponent(
        scheme
      )}&originalUrl=${encodeURIComponent(appstore)}`;
    } else {
      let hasAppOpened = false;
      const start = Date.now();
      window.location.href = scheme;
      const timer = setTimeout(() => {
        if (!hasAppOpened && Date.now() - start < 1500) {
          window.location.href = appstore;
        }
      }, 1000);

      window.addEventListener("pagehide", () => {
        hasAppOpened = true;
        clearTimeout(timer);
      });
      document.addEventListener("visibilitychange", () => {
        console.info("document.visibilityState", document.visibilityState);
        if (document.visibilityState === "hidden") {
          hasAppOpened = true;
          clearTimeout(timer);
        }
      });
    }
  };

  useEffect(() => {
    if (inWechat && wxRef.current) {
      const error = (event) => {
        // if (event.detail.errMsg === 'launch:fail') {
        copy();
        setTimeout(() => {
          window.location.href = appstore;
        }, 500);
        // }
      };
      wxRef.current.addEventListener("error", error);
      return () => {
        if (wxRef.current) {
          wxRef.current.removeEventListener("error", error);
        }
      };
    }
  }, [wxRef.current]);

  if (hideOpenApp) return null;

  return (
    <div
      className={className}
      onClick={() => {
        if (inWechat) return false;
        handleOpenApp({ onClick, scheme });
      }}
      {...rest}
    >
      {children}
      {inWechat && (
        <wx-open-launch-app
          style={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }}
          ref={wxRef}
          appid="wx5759c4a6cc338d78"
          extinfo={scheme}
        >
          <script type="text/wxtag-template">
            <style>
              {`.placeholder{
            position:absolute;
            left:0;
            top:0;
            right:0;
            bottom:0;
            }`}
            </style>
            <div className="placeholder"></div>
          </script>
        </wx-open-launch-app>
      )}
    </div>
  );
}

export default OpenApp;
