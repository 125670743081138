/**
 * @param display    滚动是否隐藏底部tab:{display='show' display='hide'}
 * @param loginbar   是否显示未登录条:true/false
 */

"use client";
import { useState, useEffect } from "react";
import { getUrlParams, getUrlCookie } from "@/utils/utils";
import useRouter from "@/hooks/useRouter";
import Link from "@/components/Link";
import { usePathname, useSearchParams } from "next/navigation";
import useReport from "@/hooks/useReport";
import { self_host } from "@/utils/fetch";

import s from "./index.module.css";

const TabBar = ({ display = "show", loginbar = false }) => {
  const { reportClick } = useReport();
  const router = useRouter();
  const [show, setShow] = useState(true);
  // const [sellRouter, setSellRouter] = useState(
  //   `${self_host}/apps/c1/sell/index.html`
  // );
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const urlParams2 = searchParams.toString();

  //tab依赖数据
  const tabs = [
    {
      key: "home",
      title: "首页",
      icon: `${s.home}`,
      selectedIcon: `${s.home_active}`,
      router: "/home",
    },
    {
      key: "cars",
      title: "买车",
      icon: `${s.buyCar}`,
      selectedIcon: `${s.buyCar_active}`,
      router: "/cars",
    },
    {
      key: "collect",
      title: "收藏",
      icon: `${s.collect}`,
      selectedIcon: `${s.collect_active}`,
      router: "/collect?v=2024724",
    },
    {
      key: "sellCar",
      title: "卖车",
      icon: `${s.sellCar}`,
      selectedIcon: `${s.sellCar_active}`,
      router: `${self_host}/apps/c1/sell/index.html`,
      getRouter: () => {
        const fromid =
          searchParams.get("fromid") || sessionStorage.getItem("fromid");
        const innerfromid =
          searchParams.get("innerfromid") ||
          sessionStorage.getItem("innerfromid");
        const pageIds = sessionStorage.getItem("pageIds")?.split(",");
        const sp = new URLSearchParams();
        if (pageIds && pageIds?.length > 0) {
          sp.set("pfrom_id", pageIds[0]);
        }
        if (fromid) {
          sp.set("from", fromid);
        }
        if (innerfromid) {
          sp.set("innerfrom", innerfromid);
        }
        return `${self_host}/apps/c1/sell/index.html?${sp.toString()}`;
      },
    },
    {
      key: "mine",
      title: "我的",
      icon: `${s.mine}`,
      selectedIcon: `${s.mine_active}`,
      router: "/mine",
    },
  ];

  useEffect(() => {
    //tab 是否收起
    if (display == "show") return;
    const tabVisbale = () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop >= "44") setShow(false);
      if (scrollTop <= "10") setShow(true);
    };
    // 添加滚动事件监听器
    window.addEventListener("scroll", tabVisbale);

    // 移除滚动事件监听器
    return () => {
      window.removeEventListener("scroll", tabVisbale);
    };
  }, []);

  //埋点
  const setReport = (title, index) => {
    switch (title) {
      case "首页":
        reportClick("a0040000000001100000", {
          cpos: index,
        });
        break;
      case "买车":
        reportClick("a0040000000001100001", {
          cpos: index,
        });
        break;
      case "收藏":
        reportClick("a0040000000001100002", {
          cpos: index,
        });
        break;
      case "卖车":
        reportClick("a0040000000001100003", {
          cpos: index,
        });
        break;
      case "我的":
        reportClick("a0040000000001100004", {
          cpos: index,
        });
        break;
    }
  };

  return (
    <>
      <div
        className={`${s.tabbar} ${show && s.slideUp} ${!show && s.slideDown}`}
      >
        <div className={s.tabArea}>
          {tabs.map((item, index) => {
            const selected = item.router.includes(pathname);

            return (
              <div key={item.key}>
                <div key={item.key} className={s.tabDot}>
                  {
                    //未点击的状态
                    !selected && (
                      <>
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            //console.log('item222',item,index)
                            setReport(item.title, index);

                            if (item.key === "sellCar") {
                              const url = item.getRouter();
                              window.location.href = url;
                            } else {
                              const upm = getUrlCookie(urlParams2);
                              const nt =
                                item.router.indexOf("?") > -1 ? "&" : "?";
                              const url = item.router + nt + (upm || "");
                              router.push(url);
                            }
                          }}
                          href={`${item.router}`}
                        >
                          <div className={`${s.icon} ${item.icon}`}></div>
                          <div className={s.title}>{item.title}</div>
                        </Link>
                      </>
                    )
                  }
                  {
                    //点击后高亮的状态
                    selected && (
                      <>
                        <div className={`${s.icon} ${item.selectedIcon}`}></div>
                        <div className={`${s.title} ${s.title_active}`}>
                          {item.title}
                        </div>
                      </>
                    )
                  }
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default TabBar;
