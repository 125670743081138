import { isEmpty, take } from "ramda";

const footprint = {
  getHref(item) {
    let href = `/cars?brand=${item.bId},${item.bName}`;
    if (item?.sId) {
      return `${href}&serial=${item.sId},${item.sName}`;
    }
    return href;
  },
  getName(item) {
    if (item.sName) {
      return item.sName;
    }
    return item.bName;
  },
  get() {
    const footprint = JSON.parse(localStorage.getItem("footprint") || "[]");
    return footprint;
  },
  set(brand, serial) {
    try {
      let footprint = JSON.parse(localStorage.getItem("footprint") || "[]");

      if (Array.isArray(serial) && !isEmpty(serial)) {
        footprint = serial
          .map((o) => ({
            bId: brand.value,
            bName: brand.name,
            sId: o.value,
            sName: o.name,
          }))
          .concat(
            footprint.filter((item) => serial.find((s) => s.value != item.sId))
          );
      } else {
        if (brand) {
          footprint = [{ bId: brand.value, bName: brand?.name }].concat(
            footprint.filter((item) => item.bId != brand.value || !!item?.sId)
          );
        }
      }
      footprint = take(10, footprint);

      localStorage.setItem("footprint", JSON.stringify(footprint));
    } catch {}
  },
};

export default footprint;
