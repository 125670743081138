import React from "react";
import Image from "@/components/Image";
import useRouter from "@/hooks/useRouter";
import style from "./index.module.css";
import Link from "@/components/Link";
import { sourceToString } from "@/hooks/useReport";
import useCommonData from "@/hooks/useCommonData";
import { defaultImage } from "@/utils/utils";

interface IMoreCarSeriesProps {
  list: ItemProps[];
  onSeriesClick?: (item: ItemProps) => void;
}

const MoreCarSeries: React.FC<IMoreCarSeriesProps> = ({
  list = [],
  onSeriesClick,
}) => {
  const router = useRouter();
  const { inMiniProgram } = useCommonData();

  console.info("imagewwwww", list);

  return (
    <div className={style.more_car_series}>
      <div className={style.title}>更多车系推荐</div>
      {list?.map((item: any, index) => {
        return (
          <Link
            key={index}
            // href={
            //   item.serialId
            //     ? `/cars?brand=${item.masterBrandId},${item.masterBrandName}&serial=${item.serialId},${item.serialName}`
            //     : `/cars?brand=${item.masterBrandId},${item.masterBrandName}`
            // }
            onClick={() => {
              if (inMiniProgram) {
                wx.miniProgram.reLaunch({
                  url: `/pages/buyCar/index?bId=${
                    item.masterBrandId
                  }&bName=${encodeURIComponent(item.masterBrandName)}&mId=${
                    item?.serialId || ""
                  }&mName=${encodeURIComponent(item?.serialName || "")}`,
                });
              } else {
                router.push(
                  item.serialId
                    ? `/cars?brand=${item.masterBrandId},${item.masterBrandName}&serial=${item.serialId},${item.serialName}`
                    : `/cars?brand=${item.masterBrandId},${item.masterBrandName}`
                );
              }

              if (onSeriesClick) {
                onSeriesClick({
                  ...item,
                  index,
                });
              }
            }}
            className="sdk-exposure-spa"
            data-exposure-extend={JSON.stringify({
              tc_extendvalue1: JSON.stringify({
                title: sourceToString(item?.source),
                serialId: item?.serialId,
                special:
                  item?.recCarPool == 1
                    ? "浏览"
                    : item?.recCarPool == 2
                    ? "收藏"
                    : item?.recCarPool == 3
                    ? "我看过的"
                    : "",
              }),
              module: "为您推荐车系",
            })}
          >
            <div className={style.car}>
              <div className={style.car_left}>
                <Image
                  src={item.serialImage || defaultImage}
                  alt={item.serialName}
                  className={style.img}
                  width={140}
                  height={92}
                />
              </div>
              <div className={style.car_right}>
                <div className={style.car_top}>{item.serialName}</div>
                <div className={style.car_bottom}>{item.carCount}辆在售</div>
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default MoreCarSeries;
